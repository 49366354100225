@media (width <= 991px){
    .images{
        width:  150px ;
        height: 150px !important;
        margin-bottom: 20px;
    }

}
@media (width <= 400px){
    .images{
        width:  100px ;
        height: 100px !important;
        margin-bottom: 20px;
    }

}