.Video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width>900px) {
  .Video {

    height: 90vh;
  }
}

.FadeEffect {
  animation: fade 99s ease-out;
  opacity: 0;
}

@keyframes fade {
  to {
    opacity: 100;
  }
}

.paragraph p {
  text-align: justify;
}

.sec2{
  background-color: #c9def4;
  
}