

.FormMainDiv {
    max-width: 1100px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .FormMainDiv .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .FormMainDiv .content .leftSide {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content .leftSide::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .content .leftSide .details {
    margin: 14px;
    text-align: center;
  }
  .content .leftSide .details i {
    font-size: 30px;
    color: #3e2093;
    margin-bottom: 10px;
  }
  .content .leftSide .details .topic {
    font-size: 18px;
    font-weight: 500;
  }
  .content .leftSide .details .textOne,
  .content .leftSide .details .textTwo {
    font-size: 14px;
    color: #afafb6;
  }
  
  .FormMainDiv .content .rightSide {
    width: 75%;
    margin-left: 75px;
  }
  .content .rightSide .topicText {
    font-size: 23px;
    font-weight: 600;
    color: #3e2093;
  }
  .rightSide .inputBox {
    height: 55px;
    width: 90%;
    margin: 12px 0;
  }
  .rightSide .inputBox input,
  .rightSide .inputBox TextArea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #f0f1f8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .rightSide .messageBox {
    min-height: 110px;
  }
  .rightSide .inputBox TextArea {
    padding-top: 6px;
  }
  .rightSide .button {
    display: inline-block;
    margin-top: 12px;
  }
  .rightSide .button .Btn {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #3e2093;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .button .Btn:hover {
    background: #5029bc;
  }
  
  @media (max-width: 950px) {
    .FormMainDiv {
      width: 90%;
      padding: 30px 40px 40px 35px;
    }
    .FormMainDiv .content .rightSide {
      width: 75%;
      margin-left: 55px;
    }
  }
  @media (max-width: 820px) {
    .FormMainDiv {
      margin: 40px 0;
      height: 100%;
    }
    .FormMainDiv .content {
      flex-direction: column-reverse;
    }
    .FormMainDiv .content .leftSide {
      width: 100%;
      flex-direction: row;
      margin-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
    }
    .FormMainDiv .content .leftSide::before {
      display: none;
    }
    .FormMainDiv .content .rightSide {
      width: 100%;
      margin-left: 32px;
    }
  }
  
  
  